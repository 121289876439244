import React from 'react'
import { Flex, Textarea, Input, Button, Box, Heading } from 'theme-ui'

export default function ContactForm({
  formName,
  variantName,
  city,
  isMultiLocationSite,
}) {
  return (
    <Box sx={customStyles.container}>
      <Heading as='h3'>RESERVATIONS OR FILL OUT THIS FORM</Heading>
      <Heading as='h5'>Reservation must be confirmed by email</Heading>
      <Flex
        as='form'
        variant={`${variantName}.formContainer`}
        name={formName ? formName : 'contactformV2'}
        method='POST'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
      >
        <Input
          type='hidden'
          name='form-name'
          value={formName ? formName : 'contactformV2'}
        />
        <Input
          variant={`${variantName}.name`}
          name='name'
          type='text'
          id='name'
          mb={3}
          placeholder='Name'
          className='inputField name'
        />
        <Input
          variant={`${variantName}.email`}
          type='email'
          name='email'
          id='email'
          mb={3}
          placeholder='Email'
          className='inputField email'
        />
        <Input
          variant={`${variantName}.phone`}
          type='phone'
          name='phone'
          id='phone'
          mb={3}
          placeholder='Phone'
          className='inputField phone'
        />
        <Input
          variant={`${variantName}.phone`}
          type='date'
          name='date'
          id='date'
          mb={3}
          placeholder='Date'
          className='inputField phone'
        />
        <Input
          variant={`${variantName}.phone`}
          type='time'
          name='time'
          id='time'
          mb={3}
          placeholder='Time'
          className='inputField phone'
        />
        <Input
          variant={`${variantName}.phone`}
          type='number'
          name='numberguests'
          id='numberguests'
          mb={3}
          placeholder='Number Of Guests'
          className='inputField phone'
        />
        <Textarea
          name='information'
          id='information'
          variant={`${variantName}.message`}
          rows='6'
          mb={3}
          placeholder='Additional Information'
          className='textField message'
        />
        {isMultiLocationSite ? (
          <Input
            sx={{ display: 'none !important' }}
            name='location'
            id='location'
            value={city}
            placeholder={city}
          />
        ) : (
          ''
        )}
        <Button className='button submitBtn'>Submit</Button>
      </Flex>
    </Box>
  )
}

const customStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem 1rem',
    h3: {
      marginBottom: '1rem',
    },
    h5: {
      marginBottom: '2rem',
      color: 'primary',
      fontFamily: 'body',
    },
  },
}
